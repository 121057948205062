// kept getting errors with different CRUD methods hitting slightly different urls...
// keep it consistent: assume we always need all of the params below!
export default (withQuotes: boolean = true): string[] => {
  const includes = [
    "cards",
    "clean-cancellation",
    "merchant",
    "merchant.platform",
    "messages",
    "notes",
    "payments",
    "property",
    "property.address",
    "current-quote",
    "resources",
    "review",
  ];

  if (withQuotes) {
    includes.push("quotes");
  }

  return includes;
};
