import React, { Component, Fragment } from "react";
import styled, { css } from "styled-components";

import PropertyList from "Containers/PropertyList";
import { H4 } from "Components/Typography";
import { Button, BUTTON_INLINE, BUTTON_SMALL } from "@madecomfy/webooi";

const Wrapper = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.charcoalLight50};
  color: ${({ theme }) => theme.charcoal};
`;

const List = styled.div`
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.charcoalLight50};
  margin-bottom: 16px;
`;

const PropertyItem = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.navyLight50};
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  margin: 4px;
  padding: 4px 8px;
`;

const PropertyActive = styled.span<any>`
  &:before {
    display: block;
    ${({ isActive }) =>
      isActive
        ? css`
            content: "Active";
            color: ${({ theme }) => theme.white};
          `
        : css`
            content: "Inactive";
            color: red;
          `}
  }
`;

export const getLabel = ({
  name,
  address,
}: {
  name: string;
  address: { [key: string]: any };
}) => {
  const title = `"${name}"`;
  if (typeof address === "string") {
    // eslint-disable-next-line
    //  console.warn("getLabel address is string", address);
    return [title, address].join(", ");
  }
  return [title, address.street, address.city].filter(Boolean).join(", ");
};
interface IProps {
  ownedProperties: any[];
  setProperties: (...args: any) => any;
}
class PropertySelector extends Component<IProps> {
  state = { properties: [] };

  handleChange = () => {
    this.props.setProperties(this.state.properties);
  };

  renderExisting = () => {
    const { ownedProperties } = this.props;
    if (ownedProperties.length) {
      return (
        <Fragment>
          <H4>Properties already attached to this user:</H4>
          <List data-test="owned-properties">
            {ownedProperties.map((property) => {
              return (
                <PropertyItem key={`existing-${property.code}`}>
                  {getLabel(property)}
                  <PropertyActive isActive={property.isActive} />
                </PropertyItem>
              );
            })}
          </List>
        </Fragment>
      );
    }
    return null;
  };

  renderSelect() {
    const { properties } = this.state;

    const addProperty = (event: any, property: any) => {
      const error = this.props.ownedProperties.find(
        ({ code }) => code === property.id,
      );
      if (error) {
        return alert("User is already attached to that property");
      }

      const doubleClick = this.state.properties.find(
        ({ id }) => id === property.id,
      );
      if (doubleClick) {
        // user has selected a property twice, remove it on second click
        removeProperty(property);
        return;
      }

      this.setState(
        { properties: properties.concat(property) },
        this.handleChange,
      );
    };

    const removeProperty = (property: any) => {
      const newProperties = properties.filter(({ id }) => id !== property.id);
      this.setState({ properties: newProperties }, this.handleChange);
    };

    return (
      <Fragment>
        <H4>Attach new properties to this user:</H4>
        <List data-test="pending-properties">
          {properties.length ? (
            properties.map((property: any) => {
              return (
                <PropertyItem key={property.id}>
                  {getLabel(property)}
                  <Button
                    label="Remove"
                    my={0}
                    onClick={() => removeProperty(property)}
                    styling={BUTTON_INLINE}
                    size={BUTTON_SMALL}
                  />
                </PropertyItem>
              );
            })
          ) : (
            <PropertyItem>Select a property below...</PropertyItem>
          )}
        </List>
        <Wrapper>
          <PropertyList
            // @ts-ignore
            autoFocus={false}
            clearCache={true}
            handleClick={addProperty}
            lazyLoad={false}
            limit={4}
            showButtons={false}
          />
        </Wrapper>
      </Fragment>
    );
  }

  render() {
    return (
      <Fragment>
        {this.renderExisting()}
        {this.renderSelect()}
      </Fragment>
    );
  }
}

export default PropertySelector;
